.App {
  text-align: center;
  background-color: #282c34;
  height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3vmin);
  color: white;
}

p {
  margin: 0px;
}

.black {
  color: black;
}
